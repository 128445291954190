import { Link, NavLink, useParams, useSearchParams } from "react-router-dom";
import { 
    useState, 
    useCallback, useEffect } from "react";
    
import { usePrefersColorSchemeLight, useResizeObserver } from '@wojtekmaj/react-hooks';

import { pdfjs, Document, Page } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './chfissue.css'
import CHFViewMatic from "../chf-viewomatic/CHFViewMatic";
import CHFToC from "../chf-toc/CHFToC";
import { generatePath, useNavigate, matchRoutes } from 'react-router';

import {slide as Menu } from 'react-burger-menu'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const resizeObserverOptions = {};

function CultureHellPDF () {
    let {zine_token, page} = useParams();
    const navigate = useNavigate();

    const [pageNumber, setPageNumber] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [containerWidth, setContainerWidth] = useState(100);
    const [containerRef, setContainerRef] = useState(null);

    const [currentMedia, setCurrentMedia] = useState({
        pdf: "",
        fullres: "",
        media: [],
        toc: []
    });

    function fetch_media () {
        fetch('/api/zine-manifest/'+zine_token).
        then(data => data.json()).
        then(json => {
            setCurrentMedia(json);
        });
    }

    useEffect(() => {
            fetch_media();
        }, [zine_token]
    );

    const onResize = useCallback((entries) => {
      const [entry] = entries;
  
      if (entry) {
        setContainerWidth(entry.contentRect.width);
      }
    }, []);
  
    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);

        if (page != undefined) {
            console.log(page);
            setPageNumber(Number(page));

        } else {
            const savedPage = JSON.parse(localStorage.getItem('page-' + zine_token));
            console.log("Loaded page " + savedPage + " at zine " + zine_token);
            if (savedPage != null) {
                navigate(""+savedPage, {replace : true})
                setPageNumber(Number(savedPage))
            } else {
                console.log("Setting initial page!");
                navigate(""+1, {replace : true});
                setPageNumber(1);
            }
        }
    }

    function iterate(to_page) {
        if (pageNumber == 0) return;
        to_page = Math.max( 1, Math.min(to_page, numPages));
        setPageNumber(to_page);
        navigate("../"+to_page, {relative: "path", replace : true});
        localStorage.setItem('page-' + zine_token, JSON.stringify(to_page));
    }

    return (
        <div id="chf">
            <div className="chf-rootnav-mobile">
                <Menu pageWrapId="page-wrap" outerContainerId="chf">
                    <NavLink to={"/culturehell/"} className="bm-item"> Back to Culture Hell... </NavLink>
                    <a  className="bm-item"
                        href={"https://shrikezone.b-cdn.net/chf/"+zine_token+"/"+currentMedia.fullres}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Download full res...</a>
                    <div className="bm-item">
                        <CHFToC toc={currentMedia.toc}/>
                    </div>
                </Menu>
            </div>
            <div className="chf-root" id="page-wrap">
                <div className="bg"/>
                <div className="chf-display"> 
                    <div className="chf-rootnav">
                        <NavLink to={"/culturehell/"} className="chf-return"> Back to Culture Hell... </NavLink>
                        <a  className="chf-dl"
                            href={"https://shrikezone.b-cdn.net/chf/"+zine_token+"/"+currentMedia.fullres}
                            target="_blank"
                            rel="noopener noreferrer"
                        >Download full res...</a>
                    </div>
                    <div className="chf-body">
                        <div className="chf-toc">
                            <CHFToC toc={currentMedia.toc}/>
                        </div>
                        <div className="chf-container" ref={setContainerRef} style={{"--cw": containerWidth+'px'}}>
                            <Document file={("https://shrikezone.b-cdn.net/chf/" + zine_token + "/" + currentMedia.pdf)} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} width={containerWidth}/>
                            </Document> 
                        </div>
                    </div>

                    <div className="chf-navbar">
                        <div className="chf-navbuttons">
                            <button onClick={() => iterate(1)}>FIRST</button>
                            <button onClick={() => iterate(pageNumber-1)}>BACK</button>
                            <button onClick={() => iterate(pageNumber+1)}>NEXT</button> 
                            <button onClick={() => iterate(numPages)}>LAST</button>  
                        </div>
                        <p>{pageNumber} / {numPages} </p>
                    </div>

                    <div className="chf-viewer" style={{"--viewer-offset": containerWidth+'px'}}>
                        <CHFViewMatic directory={zine_token} manifest={currentMedia.media} pageNumber={pageNumber}/>
                    </div>

                    <div className="chf-bitchatuser">
                        <p>Look, I don't want to be a scold or whatever, but consider turning your phone ninety degrees. You don't have to live like this. &#60;3 </p>
                    </div>
                </div>
            </div>
        </div>
    );

} export default CultureHellPDF