import { 
    useState, 
    useCallback, 
    useEffect} from "react";
import { useParams } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from "../../audioplayer/AudioPlayer.js";
import "./chfviewmatic.css"

const _ = require('lodash');

function CHFViewMatic({directory, manifest, pageNumber}) {
    const [component, setComponent] = useState("")

    useEffect(() => {
        setComponent(fetch_media());
    }, [pageNumber]);

    function token_to_path(token) {
        return "/resources/chf/" + directory + "/media/" + token.replace("-", "/");
    }

    function fetch_media() {
        if (_.isEmpty(manifest)) {
            return "";
        }
        let currentPageMedia = manifest.filter(element => element.page.includes(pageNumber));
        if (currentPageMedia != undefined) {
            return display_media(currentPageMedia);
        } else {
            return ""
        }
    }

    function display_media(media_entry) {
        return(
                <div>
                {media_entry.map(
                    media => {
                        let type = media.type;
                        if (type == "audio") {
                            return (
                                <AudioPlayer path={token_to_path(media.uri)} title={media.title} artist={media.artist}/>
                            )
                        }
                    }
                )}
            </div>
        )
    }

    return (
        <div class="viewmatic">
            {component}
        </div>
    )

} export default CHFViewMatic