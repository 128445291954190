import { Link, NavLink } from "react-router-dom";

import './navbar.css'

function NavBar() {
    return (
        <div>
            <div className='nav-bar'>
                <ul className="nav-menu">
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/srb2kart/">SRB2Kart</NavLink>
                    </li>
                    <li>                
                        <NavLink to="/culturehell/">Culture Hell Forever</NavLink>
                    </li>
                    <li>                
                        <NavLink to="/publicworks/">Public Works</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about/">About</NavLink>
                    </li>
                </ul>
            </div>
        </div>

    );
} export default NavBar;