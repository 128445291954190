import _ from "lodash"
import { Link } from "react-router-dom"
import "./chftoc.css"


function CHFToC({toc}) {

    function maptoc() {
        if (_.isEmpty(toc)) return ""
        console.log(toc);
        return toc.map(
                    (entry) => (
                    <li>
                        <Link to={"../"+entry.page} replace relative="path" reloadDocument>{entry.title}</Link>
                    </li>
                    )
                )
    }

    return (
        <div class="toc">
            <h1>Table of Contents:</h1> 
            <ul>
            {maptoc()}
            </ul>
        </div>
    )

} export default CHFToC